<template>
  <h2>Περιγραφή Παιχνιδιού</h2>
  <p>
    Το παιχνίδι Highlight Games NBA Last 60 αποτελεί μοναδική προσομοίωση λογισμικού τυχαία επιλεχθέντων καλύτερων
    στιγμιότυπων μπάσκετ από αγώνες για τη δημιουργία ευκαιριών παιχνιδιού. 
  </p>
  <h2>Περιγραφή και Έκταση Παιχνιδιού</h2>
  <p>
    Το NBA Last 60 Scheduled περιλαμβάνει μια σειρά από σελίδες που λειτουργούν βάσει χρόνου, επιτρέποντας στον τελικό
    χρήστη να ενημερώνεται για σημαντικά στοιχεία του παιχνιδιού και να στοιχηματίζει σε ευκαιρίες αγορών. Κάθε παιχνίδι
    στο NBA Last 60 διαρκεί περίπου 3 λεπτά, από την εισαγωγή μέχρι το τελικό αποτέλεσμα.
  </p>
  <p>
    Η επιλογή καλύτερων στιγμιότυπων αγώνων ΝΒΑ είναι ανεξάρτητη στατιστικά, τυχαία και μη προβλέψιμη, και παράγεται από
    Γεννήτρια Τυχαίων Αριθμών (RNG).
  </p>
  <h2>Ειδικά Στοιχήματα (Αγορές)</h2>
  <h3>Γενικός Νικητής</h3>
  <p>
    Ο παίκτης μπορεί να στοιχηματίσει στον γενικό νικητή του αγώνα βάσει τόσο των εναρκτήριων πόντων όσο και του
    αθροίσματος αυτών με τους πόντους last 60 (του τελευταίου λεπτού).   Οι συνολικοί πόντοι δεν μπορούν ποτέ να είναι
    κλήρωση στο NBA Last 60.
  </p>
  <h3>Νικητής Last 60</h3>
  <p>
    Στοίχημα στην ομάδα που θα κερδίσει σε πόντους στο τελευταίο λεπτό (last 60). Αυτό το σκορ του τελευταίου λεπτού
    (last 60) δεν μπορεί ποτέ να είναι κλήρωση.  
  </p>
  <h3>Σκοράρουν και οι Δύο Ομάδες Over 2,5 Πόντους</h3>
  <p>
    Ο παίκτης μπορεί να στοιχηματίσει και στις δύο ομάδες, τη γηπεδούχο και τη φιλοξενούμενη, ότι θα σκοράρει
    τουλάχιστον over 2,5 πόντους η καθεμία σε ένα παιχνίδι NBA Last 60.
  </p>
  <h3>Σκοράρουν και οι Δύο Ομάδες Over 3,5 Πόντους</h3>
  <p>
    Και οι δύο ομάδες, η γηπεδούχος και η φιλοξενούμενη, χρειάζεται να σκοράρουν τουλάχιστον over 3,5 πόντους η καθεμία
    σε ένα παιχνίδι NBA Last 60.
  </p>
  <h3>Συνολικά Τρίποντα Last 60</h3>
  <p>
    Πρόβλεψη του συνολικού αριθμού σκορ τρίποντων του παιχνιδιού. Αυτό μπορεί να συνδυαστεί με τέτοιον τρόπο ώστε είτε
    σκοράρει τρίποντο η γηπεδούχος, είτε η φιλοξενούμενη, το τρίποντο να υπολογίζεται στον συνολικό αριθμό τρίποντων. Ο
    μέγιστος αριθμός τρίποντων σε ένα παιχνίδι μπορεί να είναι 6.
  </p>
  <h3>Πρώτη Ομάδα Last 60 που θα Σκοράρει</h3>
  <p>Η πρώτη ομάδα, γηπεδούχος ή φιλοξενούμενη, που θα σκοράρει πόντο στο NBA Last 60.</p>
  <h3>Διαφορά Νίκης Last 60</h3>
  <p>Πρόβλεψη της διαφοράς νίκης που θα σημειώσει η νικητήρια ομάδα στο παιχνίδι Last 60.</p>
  <h3>Πρώτη Ομάδα Last 60 με 4 Πόντους</h3>
  <p>
    Η πρώτη ομάδα, γηπεδούχος ή φιλοξενούμενη, που θα σκοράρει 4 πόντους στο παιχνίδι Last 60. Το αποτέλεσμα «Καμία
    Ομάδα» δεν αποτελεί έγκυρη επιλογή για αυτή την αγορά.  
  </p>
  <h3>Ομάδα 1 Last 60 Over/Under 4,5 Πόντους</h3>
  <p>Οι πόντοι που θα σκοράρει η Ομάδα 1 (Γηπεδούχος) στο Last 60 θα είναι over ή under 4,5.</p>
  <h3>Ομάδα 2 Last 60 Over/Under 4,5 Πόντους</h3>
  <p>Οι πόντοι που θα σκοράρει η Ομάδα 2 (Φιλοξενούμενη) στο Last 60 θα είναι over ή under 4,5.</p>
  <h3>Ομάδα 1 Last 60 Over/Under 5,5 Πόντους</h3>
  <p>Οι πόντοι που θα σκοράρει η Ομάδα 1 (Γηπεδούχος) στο Last 60 θα είναι over ή under 5,5.</p>
  <h3>Ομάδα 2 Last 60 Over/Under 5,5 Πόντους</h3>
  <p>Οι πόντοι που θα σκοράρει η Ομάδα 2 (Φιλοξενούμενη) στο Last 60 θα είναι over ή under 5,5.</p>
  <h3>Νικητής Last 60 &amp; Over/Under 11,5 Πόντους</h3>
  <p>
    Πρόβλεψη του νικητή του παιχνιδιού Last 60 καθώς επίσης και του εάν το συνολικό σκορ πόντων στο Last 60 θα είναι
    over ή under 11,5 πόντους, ως σύνθετο στοίχημα. (Η παρούσα αγορά είναι σταθερά στο 11,5 και δεν προσφέρει
    διαφορετικές τιμές όπως συμβαίνει με κάποιες από τις ανωτέρω αγορές.)  
  </p>
  <h3>Νικητής Last 60 &amp; Διαφορά Νίκης</h3>
  <p>
    Πρόβλεψη του νικητή του παιχνιδιού Last 60 και της διαφοράς νίκης, δηλαδή κατά πόσους πόντους θα κερδίσει η εν λόγω
    ομάδα.
  </p>
</template>
